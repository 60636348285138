import React from 'react';
import { Link } from 'gatsby';
import { Button, Heading, Text, Flex, FullHeight } from '@marvelapp/ui';

import PageMeta from '../components/PageMeta';

export default function NotFoundPage() {
  return (
    <FullHeight>
      <Flex
        alignItems="center"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <PageMeta title="404: Not Found" />
        <Heading marginBottom={2} textAlign="center">
          Not Found
        </Heading>
        <Text marginBottom={3} textAlign="center">
          You just hit a route that doesn&#39;t exist... the sadness.
        </Text>
        <Button as={Link} kind="green" size={2} to="/">
          Go back
        </Button>
      </Flex>
    </FullHeight>
  );
}
